/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import styled from '@emotion/styled'
import { graphql, useStaticQuery, Link } from 'gatsby'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import BackgroundImage from 'gatsby-background-image'
import SliderImage1 from '../images/pixels/ExtendIT-Slider1.jpg'
import SliderImage2 from '../images/pixels/ExtendIT-Slider2.jpg'
import SliderImage3 from '../images/pixels/ExtendIT-Slider3.jpg'
import SliderImage4 from '../images/pixels/ExtendIT-Slider4.jpg'
import SliderImage5 from '../images/pixels/ExtendIT-Slider5.jpg'
import SwiperCore, { Autoplay, EffectFade, Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
require('swiper/swiper.scss')
require('swiper/components/effect-fade/effect-fade.scss')
require('swiper/components/navigation/navigation.scss')

SwiperCore.use([Autoplay, EffectFade, Navigation])

const Hero = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  position: relative;

  width: 100%;

  .swiper-container,
  .swiper-wrapper {
    width: 100%;
    @media (max-width: ${props => props.theme.responsive.mediumMax}) {
      min-width: 100vw;
    }
  }

  .swiper-slide {
    width: 100%;
    max-width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    background-color: #fff;

    @media (max-width: ${props => props.theme.responsive.mediumMax}) {
      flex-direction: column;
    }
  }

  .swiper-slide.swiper-slide-active {
    z-index: 98;
  }

  .swiper-button-prev,
  .swiper-button-next {
    position: absolute;
    top: 50%;
    width: 10px;
    height: 20px;
    margin-top: 0;
    z-index: 10;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    width: 10px;
    height: 20px;
    top: auto;
    bottom: 10px;
    color: #000;
    z-index: 99;
  }

  .swiper-button-prev:after,
  .swiper-container-rtl .swiper-button-next:after {
    content: 'prev';
  }

  .swiper-button-next:after,
  .swiper-container-rtl .swiper-button-prev:after {
    content: 'next';
  }

  .swiper-button-prev:after,
  .swiper-button-next:after {
    font-family: swiper-icons;
    font-size: ${props => props.theme.fontSizes[2]}px;
    text-transform: none !important;
    letter-spacing: 0;
    text-transform: none;
    font-variant: initial;
    line-height: 1;
  }

  .swiper-button-prev,
  .swiper-button-next {
    top: 80px;
    bottom: auto;
    left: auto;
  }

  .swiper-button-prev {
    right: 32px;
  }

  .swiper-button-next {
    right: 15px;
  }

  @media (min-width: ${props => props.theme.responsive.medium}) {
    .swiper-button-prev,
    .swiper-button-next {
      top: auto;
      bottom: 9px;
    }

    .swiper-button-prev {
      left: 60px;
    }

    .swiper-button-next {
      right: auto;
      left: 77px;
    }
  }

  .swiper-button-prev:after,
  .swiper-button-next:after {
    font-size: 18px;
  }
`

const SliderCenter = styled.div`
  width: 100%;
  display: flex;
  flex: 0 0 438px;
  position: relative;

  @media (max-width: ${props => props.theme.responsive.mediumMax}) {
    flex: 1 0 auto;
    min-height: 60vh;
    height: 60vh;
    max-width: 100%;
    border-right: none;
  }

  @media (max-width: ${props => props.theme.responsive.smallMax}) {
    border-right: none;
  }

  .teamBG {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: center 10% !important;
    img {
      object-position: center 10% !important;
    }
    &:before,
    &:after{
      background-position: center 10% !important;
    }
  }
`

const SliderCenterBackgroundImage = styled(BackgroundImage)`
  background-position: center;
  @media (max-width: ${props => props.theme.responsive.largeMax}) {
    background-position: center 30%;
  }
  @media (max-width: ${props => props.theme.responsive.mediumMax}) {
    background-position: center top;
  }
`
const Slider1CenterBackgroundImage = styled(SliderImage1)`
  background-position: center;
  @media (max-width: ${props => props.theme.responsive.largeMax}) {
    background-position: center 30%;
  }
  @media (max-width: ${props => props.theme.responsive.mediumMax}) {
    background-position: center top;
  }
`

const HeroHeadline = styled.h1`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 439px);
  height: auto;
  padding: 20px 60px;
  font-size: ${props => props.theme.fontSizes[3]}px;
  z-index: 2;
  border-bottom: 2px solid #000;

  & > strong{
    font-weight: 700;
  }
  
  @media (max-width: ${props => props.theme.responsive.largeMax}) {
    padding: 10px 30px;
    font-size: ${props => props.theme.fontSizes[2]}px;
  }

  @media (max-width: ${props => props.theme.responsive.mediumMax}) {
    padding: 10px 15px;
    background-color: #fff;
    border-bottom: 2px solid #000;
    border-right: none;
    font-size: ${props => props.theme.fontSizes[1]}px;
    width: 100% !important;
  }
  `

const SliderLeft = styled.div`
  display: flex;
  flex-direction: column;
  flex: auto;
  border-right: 2px solid #000;
  width: 100%;
  padding: 120px 8px 60px;
  font-size: ${props => props.theme.fontSizes[5]}px;

  @media (max-width: ${props => props.theme.responsive.largeMax}) {
    font-size: ${props => props.theme.fontSizes[4]}px;
    padding: 120px 30px 30px;
  }

  @media (max-width: ${props => props.theme.responsive.mediumMax}) {
    padding: 70px 15px 30px;
    background-color: #fff;
    border-bottom: 2px solid #000;
    border-right: none;
    font-size: ${props => props.theme.fontSizes[2]}px;
  }

  @media (max-width: ${props => props.theme.responsive.smallMax}) {
    padding: 90px 15px 30px;
  }
`

const MemberName = styled.div`
  font-size: ${props => props.theme.fontSizes[8]}px;
  line-height: 1em;
  text-transform: uppercase;

  @media (max-width: ${props => props.theme.responsive.largeMax}) {
    font-size: ${props => props.theme.fontSizes[6]}px;
  }

  @media (max-width: ${props => props.theme.responsive.mediumMax}) {
    font-size: ${props => props.theme.fontSizes[5]}px;
    margin-bottom: 10px;
  }
`

const Slidertext = styled.div`
  font-size: 70px;
  line-height: 1em;
  font-family: 'PPE_light', Helvetica, sans-serif;

  @media (max-width: ${props => props.theme.responsive.largeMax}) {
    font-size: ${props => props.theme.fontSizes[6]}px;
  }

  @media (max-width: ${props => props.theme.responsive.mediumMax}) {
    font-size: ${props => props.theme.fontSizes[5]}px;
    margin-bottom: 10px;
  }
`

const MemberText = styled.div`
  font-size: ${props => props.theme.fontSizes[5]}px;
  line-height: 1.4;
  white-space: pre-line;

  @media (max-width: ${props => props.theme.responsive.largeMax}) {
    font-size: ${props => props.theme.fontSizes[3]}px;
    line-height: 1.5;
  }
  p {
    @media (max-width: ${props => props.theme.responsive.mediumMax}) {
      font-size: ${props => props.theme.fontSizes[2]}px;
      line-height: 1.2;
      margin-bottom: 1px;
      display: inline-block;
    }
  }
`

const HeroDescription = styled.div`
  @media (min-width: ${props => props.theme.responsive.large}) {
    margin-top: 40px;
    margin-bottom: 0px;
  }

  p a {
    border: 2px solid ${props => props.theme.colors.green};
    padding: 8px 12px;
    opacity: 1 !important;
    &:hover {
      background-color: ${props => props.theme.colors.green};
    }

    @media (max-width: ${props => props.theme.responsive.largeMax}) {
      padding: 2px 12px !important;
    }

    @media (max-width: ${props => props.theme.responsive.mediumMax}) {
      padding: 1px 12px !important;
    }
    @media (max-width: ${props => props.theme.responsive.smallMax}) {
      padding: 1px 6px !important;
    }
  }

  p a:first-of-type {
    border: 2px solid ${props => props.theme.colors.green};
    border-radius: 999px;
    padding: 8px 12px;
    opacity: 1 !important;
    &:hover {
      background-color: ${props => props.theme.colors.green};
    }
  }
`

const Homepage = () => {
  const data = useStaticQuery(graphql`
    {
      allContentfulTeam(
        filter: { featured: { eq: true } }
        sort: { order: ASC, fields: order }
      ) {
        edges {
          node {
            contentful_id
            featuredName
            featuredText {
              json
            }
            hauptbild {
              fluid(
                maxWidth: 438
                maxHeight: 643
                cropFocus: FACE
                resizingBehavior: FILL
                quality: 90
              ) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
        }
      }
      file(relativePath: { eq: "extendIT-team-min.jpg" }) {
        childImageSharp {
          fluid(fit: COVER, maxWidth: 438, quality: 90, cropFocus: ATTENTION) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
<Hero>
  <HeroHeadline>Ihr persönlicher IT-Dienstleister für <strong>Mac</strong> und <strong>Windows</strong></HeroHeadline>
  <Swiper
    spaceBetween={0}
    slidesPerView={1}
    speed={450}
    autoplay={{
      delay: 7000,
    }}
    effect="fade"
    navigation
    loop
  >
    <SwiperSlide virtualIndex={5}>
      <SliderLeft>
        <p>Das ist</p>
        <HeroDescription>
          <MemberName css={css`text-transform: none !important;`}>EXTENDIT</MemberName>
          <MemberText>
            <p>
              das freundliche und kompetente Team, das Sie gerne in allen
              IT-Belangen unterstützt.
            </p>
            <p>Sie möchten mehr erfahren?</p>
            <p>
              Zum <Link to={'/team/'}>Team</Link> oder zu{' '}
              <Link to={'/leistungen/it-dienstleister/'}>IT-Support</Link>
            </p>
          </MemberText>
        </HeroDescription>
      </SliderLeft>
      <SliderCenter>
        <SliderCenterBackgroundImage
          Tag="div"
          className="teamBG"
          fluid={data.file.childImageSharp.fluid}
          backgroundColor={`#f2f2f2`}
          title="Fullscreen Background"
          id="fullscreenbg"
          role="img"
          aria-label="Fullscreen Background"
        />
      </SliderCenter>
    </SwiperSlide>
    {data.allContentfulTeam.edges.map(({ node: mitarbeiter }, index) => (
      <SwiperSlide key={mitarbeiter.contentful_id} virtualIndex={index + 1}>
        <SliderLeft>
          <p>Das ist</p>
          <HeroDescription>
            <MemberName>{mitarbeiter.featuredName}</MemberName>
            <MemberText>
              {documentToReactComponents(mitarbeiter.featuredText.json)}
            </MemberText>
          </HeroDescription>
        </SliderLeft>
        <SliderCenter>
          <SliderCenterBackgroundImage
            Tag="div"
            className="teamBG"
            fluid={mitarbeiter.hauptbild.fluid}
            backgroundColor={`#f2f2f2`}
            title="Fullscreen Background"
            id="fullscreenbg"
            role="img"
            aria-label="Fullscreen Background"
          />
        </SliderCenter>
      </SwiperSlide>
    ))}
  </Swiper>
</Hero>
  )
}

export default Homepage
